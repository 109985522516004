import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_LAMBDA_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function transformDocxToHtmlStr(url: string) {
  try {
    const { data } = await apiClient.post('/docx-to-html', {
      data: url,
    });

    return data;
  } catch (error) {
    console.error('Failed to transform docx file', { error });

    throw error;
  }
}
