import MondaySdk from 'monday-sdk-js';
import { toCamelCase } from '../utils';
import { Item } from '../types';

class MondayApi {
  monday = MondaySdk();

  constructor() {
    this.monday.setToken(process.env.REACT_APP_ACCESS_TOKEN!);
  }

  async getBoardStructure(boardId: number) {
    try {
      const query = `
        query($boardId:Int!) {
          boards(ids: [$boardId]) {
            items {
              id
              name,
              column_values {
                type
                id
                title
                value
                text
              }
            }
          }
        }
      `;

      const variables = { boardId };
      const result = toCamelCase(await this.monday.api(query, { variables })) as {
        data: { boards: { items: Item[] }[] };
      };

      return result.data.boards[0].items;
    } catch (error) {
      throw new Error();
    }
  }
}

export const mondayApi = new MondayApi();
