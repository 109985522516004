import { FC } from 'react';
import { Card } from '../../components';
import { appStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import './Home.scss';

export const Home: FC = observer(() => {
  const { appsList } = appStore;
  return (
    <div className="home-page">
      <div className="home-page_banner">
        <span className="home-page_text">
          Perfect your workflow with
          <br /> powerful internal apps
        </span>
      </div>
      <div className="home-page_cards">
        {appsList.map(item => (
          <Card
            title={item.title}
            description={item.description}
            slug={item.slug}
            videoUrl={item.videoUrl}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
});
