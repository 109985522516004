import { FC, useLayoutEffect, useState } from 'react';
import { Button, Loader } from 'monday-ui-react-core';
import { transformDocxToHtmlStr } from '../../services';
import { useParams } from 'react-router-dom';
import { CustomLink } from '../../components';
import { observer } from 'mobx-react-lite';
import { appStore } from '../../stores';
import './Info.scss';

export const Info: FC = observer(() => {
  const [htmlContent, setHtmlContent] = useState<string | undefined>('');
  const { id } = useParams();
  const { appsDetails, setDocInfo } = appStore;

  const appInfo = appsDetails[id!];

  if (!id || !appInfo) {
    return <h5>Invalid app id</h5>;
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      let docInfo = appInfo.docInfo;
      if (!appInfo.docInfo) {
        docInfo = (await transformDocxToHtmlStr(appInfo.docUrl)).data as string;
        setDocInfo(id, docInfo);
      }

      setHtmlContent(docInfo);
    })();
  }, []);

  function onInstalClick() {
    window.open(appInfo.instalLink, '_blank');
  }

  function onMarketplaceClick() {
    window.open(appInfo.marketplaceLink, '_blank');
  }

  return (
    <div className="info-page">
      <div className="info-page_header">
        <CustomLink linkBack to={'/'}>
          Go back
        </CustomLink>
        <div className="info-page_wrapper">
          <h2 className="info-page_title">{appInfo.title}</h2>
          <div className="info-page_buttons">
            <Button size={Button.sizes!.MEDIUM} onClick={onInstalClick}>
              Get Started
            </Button>
            {appInfo.marketplaceLink && (
              <Button onClick={onMarketplaceClick} size={Button.sizes!.MEDIUM}>
                See on Marketplace
              </Button>
            )}
          </div>
        </div>
        <div className="info-page_wrapper">
          <span className="info-page_description">{appInfo.description}</span>

          <div className="info-page_image">
            <video controls>
              <source src={appInfo.videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="info-page_main">
        {htmlContent ? (
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        ) : (
          <div className="loader">
            <Loader size={Loader.sizes!.LARGE} />
          </div>
        )}
      </div>
    </div>
  );
});
