import { FC } from 'react';
import { CustomLink } from '../Link';
import './Card.scss';

type CardProps = {
  title: string;
  description: string;
  slug: string;
  videoUrl: string;
};

export const Card: FC<CardProps> = ({ title, description, videoUrl, slug }) => {
  return (
    <div className="card">
      <div className="card_block">
        <h2 className="card_title">{title}</h2>
        <span className="card_description">{description}</span>
        <CustomLink to={`/apps/${slug}`}> Learn More</CustomLink>
      </div>
      <div className="card_img">
        <video controls>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};
