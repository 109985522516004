import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { getStructure, mondayApi } from '../services';
import { DataType } from '../types';
import { APP_STATUSES } from '../constants';

const boardId = Number(process.env.REACT_APP_BOARD_ID!);

class AppStore {
  constructor() {
    makeAutoObservable(this);
  }

  boardId!: number;
  @observable error = '';
  @observable loading = true;
  @observable itemsInfo: Record<string, DataType> = {};

  @action
  async init() {
    try {
      const items = await mondayApi.getBoardStructure(boardId);
      const structure = await getStructure(items);
      runInAction(() => {
        this.loading = false;
        this.itemsInfo = structure;
      });
    } catch {
      runInAction(() => {
        this.error = 'Something went wrong';
      });
    } finally {
      this.loading = false;
    }
  }

  @action
  setDocInfo = (id: string, docInfo: string) => {
    this.itemsInfo[id].docInfo = docInfo;
  };

  @computed
  get appsList() {
    const appList = Object.values(this.itemsInfo);
    const filteredList = appList.filter(app => app.status === APP_STATUSES.PUBLISHED);

    return filteredList;
  }

  @computed
  get appsDetails() {
    const filteredAppsInfo = {} as Record<string, DataType>;

    Object.entries(this.itemsInfo).forEach(([key, value]) => {
      if (value.status === APP_STATUSES.NOT_DISPLAYED) return;
      filteredAppsInfo[key] = value;
    });

    return filteredAppsInfo;
  }
}

export const appStore = new AppStore();
