export const COLUMN_NAMES = {
  DESCRIPTION: 'Description',
  VIDEO_LINK: 'Promo Video',
  INSTAL_LINK: 'Installation Link',
  MARKETPLACE_LINK: 'Link to Marketplace',
  DOCX_LINK: 'How To Use Link',
  SLUG: 'Slug',
  APP_STATUS: 'App Status',
};

export const APP_STATUSES = {
  NOT_DISPLAYED: 'Not displayed',
  IN_REVIEW: 'In Review',
  PUBLISHED: 'Published',
};
