import { Item, DataType, ColumnValue } from '../types';
import { COLUMN_NAMES } from '../constants';

export async function getStructure(items: Item[]) {
  const structure = items.reduce((acc, item) => {
    const { id, name, columnValues } = item;
    const columnValueRecord = getColumnValueRecord(columnValues);
    const slug = columnValueRecord[COLUMN_NAMES.SLUG].text || id;

    const result = {
      id,
      title: name,
      instalLink: getLinkUrl(columnValueRecord[COLUMN_NAMES.INSTAL_LINK]),
      marketplaceLink: getLinkUrl(columnValueRecord[COLUMN_NAMES.MARKETPLACE_LINK]),
      docUrl: getLinkUrl(columnValueRecord[COLUMN_NAMES.DOCX_LINK]),
      videoUrl: getLinkUrl(columnValueRecord[COLUMN_NAMES.VIDEO_LINK]),
      description: columnValueRecord[COLUMN_NAMES.DESCRIPTION].text,
      status: columnValueRecord[COLUMN_NAMES.APP_STATUS].text,
      slug,
    };

    acc[slug] = result;
    return acc;
  }, {} as Record<string, DataType>);

  return structure;
}

function getColumnValueRecord(columnValues: ColumnValue[]): Record<string, ColumnValue> {
  return columnValues.reduce((acc, colValue) => {
    const title = colValue.title;
    acc[title] = colValue;
    return acc;
  }, {} as Record<string, ColumnValue>);
}

function getLinkUrl(linkCol?: ColumnValue): string {
  if (!linkCol) return '';

  const { value } = linkCol;

  if (!value) return '';

  const parsedValue = JSON.parse(value);

  return parsedValue?.url || '';
}
