import { FC } from 'react';
import { ReactComponent as MondayLogo } from '../../assets/mondayLogo.svg';
import { Search } from 'monday-ui-react-core';
import { Link } from 'react-router-dom';
import './Header.scss';

export const Header: FC = () => {
  return (
    <header className="header">
      <div className="header_logo">
        <Link to="/">
          <MondayLogo />
        </Link>
      </div>
      <div className="header_dropdown">
        <Search placeholder="Search for apps" />
      </div>
    </header>
  );
};
