import { FC } from 'react';
import './Footer.scss';

export const Footer: FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer_left-side">
        <span>{currentYear}. All right reserved.</span>
      </div>
      <div className="footer_right-side">
        <span>
          <a href="#">Privacy Policy</a>
        </span>
        <span>
          <a href="#">Terms of Service</a>
        </span>
        <span>
          <a href="#">Cookies Settings</a>
        </span>
      </div>
    </footer>
  );
};
