import { FC } from 'react';
import { ReactComponent as ArrowRight } from '../../assets/arrowRight.svg';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import './Link.scss';

interface LinkProps {
  children: string;
  to: string;
  linkBack?: boolean;
}

export const CustomLink: FC<LinkProps> = ({ children, to, linkBack = false }) => {
  return (
    <Link to={to} className={cn('link', { link_back: linkBack })}>
      {children}
      <div className="link_arrow">
        <ArrowRight />
      </div>
    </Link>
  );
};
