import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Footer, Header } from './components';
import { Home, Info } from './pages';
import { appStore } from './stores';
import { observer } from 'mobx-react-lite';
import { Loader } from 'monday-ui-react-core';
import 'monday-ui-react-core/dist/main.css';
import './App.scss';

export const App = observer(() => {
  useEffect(() => {
    (async () => {
      await appStore.init();
    })();
  }, []);

  if (appStore.loading) {
    return (
      <div className="app_loader">
        <Loader size={Loader.sizes!.LARGE} />
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <div className="app_content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apps" element={<Home />} />
          <Route path="/apps/:id" element={<Info />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
});
